<template>
  <div class="discharge-authority-container">
    <Jotform :src="url" :initial-height="1100" />
  </div>
</template>

<script>
import Jotform from '@/components/common/JotForm.vue';

export default {
  components: {
    Jotform,
  },
  computed: {
    url() {
      return process.env.VUE_APP_CUSTOM_THEME_JOTFORMS_DISCHARGE_AUTHORITY_URL ?? '';
    },
  },
};
</script>

<style lang="scss" scoped>
.discharge-authority-container {
  width: 100%;
}
</style>
