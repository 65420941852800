<template>
  <Card class="login-card">
    <template #title>
      <p class="hk-grotest-bold header-text">Log in to your account</p>
    </template>
    <template #content>
      <div class="mb-3">
        <div class="mb-1 label">Enter your mobile number</div>
        <div class="position-relative">
          <TextField
            type="text"
            pattern="^((\+|04)([0-9]*))$"
            placeholder="Mobile number"
            width="100%"
            :value="formData.to"
            @onInput="getFormData($event, 'to')"
            @keypress="noWhiteSpaceKeys"
          />
          <div
            v-if="!isAdmin"
            class="position-absolute send-code cursor-pointer"
            @click="sendOTP"
            :class="{ 'send-code-disabled': !formData.to }"
          >
            Send code
          </div>
        </div>
      </div>
      <div>
        <div class="mb-1 label">Enter the <span v-if="!isAdmin"> 6 digit</span> verification code</div>
        <TextField
          type="text"
          placeholder="Verification code"
          width="100%"
          pattern="\d{6}"
          maxLength="6"
          :value="formData.otp"
          @onInput="getFormData($event, 'otp')"
          @keyup.enter="signin()"
        />
        <div></div>
        <Button class="mt-5" label="Sign in" width="100%" type="primary" @click="signin()" />
      </div>
      <a v-if="!isOriginWL" :href="loanAppUrl" target="_blank" class="apply-now flex justify-content-center mt-3">
        <div class="cursor-pointer task-hover">Apply now</div>
      </a>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Card from 'primevue/card';
//import LogRocket from "logrocket";

const errorMessages = {
  'applicant-not-verified-error': 'Applicant declaration incomplete',
  'invalid-mobile-phone-number-error': 'Invalid mobile phone number',
  'invalid-otp-code-error': 'Invalid OTP code',
};

const phoneValidation = new RegExp(/^((\+|04)([0-9]*))$/);

export default {
  name: 'Login',
  setup() {
    return {
      loanAppUrl: process.env.VUE_APP_LOAN_APPLICATION_URL,
    };
  },
  data() {
    return {
      formData: {
        to: '',
        otp: '',
      },
      isOtpReceived: false,
      isAdmin: false,
    };
  },
  components: {
    Card,
  },
  methods: {
    ...mapActions({
      verifyOtp: 'auth/verifyOtp',
      fetchOtp: 'auth/fetchOtp',
      getCustomerById: 'settings/getCustomerById',
    }),
    async sendOTP() {
      if (!this.formData.to) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Mobile number is missing',
          life: 3000,
        });
        return;
      }
      if (!phoneValidation.test(this.formData.to)) {
        return this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Phone number must contain only numbers and start with "04" for Australian or + for international numbers',
          life: 3000,
        });
      }
      if (this.formData.to.length < 6 || this.formData.to.length > 15) {
        return this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Phone number must be at least 6 characters and fewer than 15',
          life: 3000,
        });
      }
      try {
        await this.fetchOtp({ mobilePhoneNumber: this.formData.to });
        await this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'OTP sent successfully',
          life: 3000,
        });
        this.isOtpReceived = true;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          console.log('code', error.response.data.message);
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: errorMessages[error.response.data.message] || 'Something went wrong',
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
        this.isOtpReceived = false;
      }
    },
    async signin() {
      if (!this.formData.to) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Mobile number is missing',
          life: 3000,
        });
        return;
      }
      if (!phoneValidation.test(this.formData.to)) {
        return this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Phone number must contain only numbers and start with "04" for Australian or + for international numbers',
          life: 3000,
        });
      }
      if (this.formData.to.length < 6 || this.formData.to.length > 15) {
        return this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Phone number must be at least 6 characters and fewer than 15',
          life: 3000,
        });
      }
      if (!this.formData.otp) {
        await this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Verification code is missing',
          life: 3000,
        });
        return;
      }
      try {
        await this.verifyOtp({
          mobilePhoneNumber: this.formData.to,
          otp: this.formData.otp,
        });
        await this.getCustomerById();
        /*  LogRocket.identify(this.profileDetails.email, {
          name: this.profileDetails.firstName + this.profileDetails.lastName,
          email: this.profileDetails.email,
        }); */
        await this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Logged in successfully',
          life: 3000,
        });
        this.$router.push('/dashboard');
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: errorMessages[error.response.data.message] || 'Something went wrong',
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Invalid login details',
            life: 3000,
          });
        }
      }
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    noWhiteSpaceKeys(event) {
      if (event.key.match(/\s/)) {
        event.preventDefault();
      }
    },
  },
  watch: {
    $route() {
      this.isAdmin = this.$route.query.admin ? this.$route.query.admin : false;
      this.isOtpReceived = this.isAdmin;
    },
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
    isOriginWL() {
      return process.env.VUE_APP_ORIGIN_TYPE !== 'bridgit';
    },
  },
  created() {
    this.isAdmin = this.$route.query.admin && this.$route.query.admin == 'true' ? true : false;
    this.isOtpReceived = this.isAdmin;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: 100% !important;
  width: 408px;
}

.label {
  font-family: Gordita-medium;
  font-size: 16px;
}

.header-text {
  font-size: 28px;
  margin: 0;
}

:deep(.p-card-body) {
  padding: 2.5rem 2.5rem 2rem 2.5rem !important;
}

:deep(.p-float-label) {
  margin: 0 !important;
}

:deep(.p-card-title) {
  color: $secondary-color !important;
  margin: 0 !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: $secondary-color !important;
  font-weight: 500;
  font-size: 14px;
  padding: 0 !important;
  margin-top: 24px;
}
.apply-now {
  color: rgba(2, 0, 56, 0.4) !important;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.send-code {
  top: 10px;
  right: 10px;
  color: #54b7f9;
  font-weight: 600;
  font-size: 1rem;
}
.send-code-disabled {
  color: #92cef7;
  pointer-events: none;
}
.task-hover:hover {
  color: $primary-color;
}
@media (max-width: 480px) {
  .login-card {
    margin: 20px;
  }

  .header-text {
    font-size: 24px;
  }
}
</style>
